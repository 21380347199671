import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { de, en, es, fr, it } from '../assets/translations';

const msg = {
  'en': en,
  'fr': fr,
  'it': it,
  'de': de,
  'es': es,
};

const AppConfigurationContext = createContext(null);

export const AppConfigurationProvider = ({children, configuration, cart}) => {
  const [shopId, setShopId] = useState(null)
  const [storyId, setStoryId] = useState(null)
  const [storefrontToken, setStorefrontToken] = useState(null)
  const [locale, setLocale] = useState('en')
  const [countryCode, setCountryCode] = useState(null)
  const [videos, setVideos] = useState([])
  const [cartVariants, setCartVariants] = useState([])
  const [messages, setMessages] = useState(null);
  const [initVideoIndex, setInitVideoIndex] = useState(null)
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    setMessages(msg[locale]);
  }, [locale])

  useEffect(() => {
    return () => {
      setVideos([])
      setShopId(null)
      setStoryId(null)
      setStorefrontToken(null)
      setCountryCode(null)
      setCartVariants([])
      setLocale('en')
      setInitVideoIndex(null)
      setSessionId(null)
    }
  }, [])

  useEffect(() => {
    if (configuration) {
      setVideos(configuration.videos)
      setShopId(configuration.shopId)
      setStoryId(configuration.storyId)
      setStorefrontToken(configuration.storefrontToken)
      setCountryCode(configuration.countryCode)
      setLocale(configuration.locale)
      setInitVideoIndex(configuration.initVideoIndex)
      setSessionId(`${configuration.sessionId}-${new Date().getTime()}`)
    }
  }, [configuration])

  useEffect(() => {
    setCartVariants(cart)
  }, [cart.length])

  return (
    <AppConfigurationContext.Provider
      value={{
        videos,
        shopId,
        storyId,
        storefrontToken,
        countryCode,
        cartVariants,
        locale,
        initVideoIndex,
        sessionId
      }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </AppConfigurationContext.Provider>
  )
}

export function useAppConfigurationProvider() {
  const context = useContext(AppConfigurationContext);
  if (!context) {
    throw new Error('useAppConfigurationProvider must be used within the scope of AppConfigurationContext');
  }
  return context;
}
