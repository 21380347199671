export const currencyFormatter = (value, currencyCode) => {
  currencyCode = currencyCode ? currencyCode : 'USD';
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
  }).format(value);
};

export const parseProductVariants = (product) => {
  return ({
    price: product.priceRange.minVariantPrice.amount,
    currencyCode: product.priceRange.minVariantPrice.currencyCode,
    minVariantPrice: product.priceRange.minVariantPrice.amount,
    maxVariantPrice: product.priceRange.maxVariantPrice.amount,
    compareAtPrice: product.compareAtPriceRange.maxVariantPrice.amount === '0.0' ? null : product.compareAtPriceRange.maxVariantPrice.amount,
    variants: product.variants.nodes.map(variant => ({
      compareAtPrice: variant.compareAtPrice?.amount,
      currencyCode: variant.price.currencyCode,
      price: variant.price.amount,
      image: variant.image?.thumbnail,
      title: variant.title,
      id: parseIdString(variant.id)
    }))
  })
}

const parseIdString = (idString) => {
  if (typeof idString === 'number') {
    return idString.toString();
  }

  const idStringArray = idString.split('/');
  return idStringArray[idStringArray.length - 1];
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
