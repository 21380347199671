import React, { createContext, useContext, useEffect } from 'react';
import { MESSAGE_APP_NAME, SHOPPABLE_APP_EVENT } from '../const';

const WindowMessagingContext = createContext(null);

export const WindowMessagingProvider = ({children, onMessageReceived}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sourceUrlOrigin = urlParams.get('shopOrigin');

  const shopifyWindowRef = window.parent;

  const postMessage = (type, payload = null) => {
    shopifyWindowRef.window.postMessage({ app: MESSAGE_APP_NAME, type, payload }, sourceUrlOrigin);
  }

  const messageReceiver = ({data}) => {
    onMessageReceived(data.type, data.payload)
  }

  useEffect(() => {
    window.self.addEventListener('message', messageReceiver);
    postMessage(SHOPPABLE_APP_EVENT.APP_INITIALIZED)
    return () => {
      if (window.self) {
        window.self.removeEventListener('message', messageReceiver);
      }
    }
  }, [])

  return (
    <WindowMessagingContext.Provider
      value={{
        postMessage
      }}
    >
      {children}
    </WindowMessagingContext.Provider>
  )
}

export function useWindowMessagingProvider() {
  const context = useContext(WindowMessagingContext);
  if (!context) {
    throw new Error('useWindowMessagingProvider must be used within the scope of WindowMessagingContext');
  }
  return context;
}
