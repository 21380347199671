import React, { lazy, Suspense } from 'react';
import Spinner from '../../components/spinner';
import { VideoStoryProvider } from '../../providers/VideoStoryProvider';

const VideosSequence = lazy(() => import('../../components/videosSequence'));

const ShoppableVideoStory = () => {
  return (
    <VideoStoryProvider>
      <Suspense fallback={<Spinner />}>
        <VideosSequence />
      </Suspense>
    </VideoStoryProvider>
  )
}


export default ShoppableVideoStory;
