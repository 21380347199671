export const MESSAGE_APP_NAME = 'shoppableApp';

export const BOUTIQ_APP_CART_KEY = 'boutiqapp';

export const SHOPPABLE_APP_EVENT = {
  APP_INITIALIZED: 'initialized',
  RESET_APP_STATE: 'resetAppState',
  CLOSE: 'close',
  ADD_TO_CART: 'add_to_cart',
  OPEN_PAGE: 'open_page',
  OPEN_BOUTQ: 'open_boutiq',
  UPDATE_CART_CALLBACK: 'update_cart',
  OPEN_CART: 'open_cart'
}

export const VIDEO_EVENTS = {
  VIEW: 'view',
  CLICK: 'click'
}

export const PRODUCT_PREVIEW_SIZE = {
  collapsed: 'collapsed',
  expanded: 'expanded'
}

export const ACTION_TYPE = {
  NOT_SELECTED: 'not_selected',
  ADD_TO_CART: 'add_to_cart',
  SHOW_PRODUCT_PAGE: 'view_product',
  GO_TO_PAGE: 'go_to_page',
  OPEN_BOUTQ: 'open_boutq',
}
