import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAppConfigurationProvider } from './AppConfigurationProvider';
import { isSafari } from '../utils';

const VideoStoryContextContext = createContext(null);

export const VideoStoryProvider = ({children}) => {
  const {videos, initVideoIndex} = useAppConfigurationProvider()

  const [shopVideosStates, setShopVideosStates] = useState([]);
  const [isShopVideoStatesRefreshed, setIsShopVideoStatesRefreshed] = useState(false)
  const [isMuted, setIsMuted] = useState(false);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(0);
  const [videoElement, setVideoElement] = useState(null);
  const [isUserInteractWithVideoPlayer, setIsUserInteractWithVideoPlayer] = useState(false);
  const [stateChecked, setStateChecked] = useState(false);
  const [progressAnimateDuration, setProgressAnimateDuration] = useState(null);
  const [safariMuteButtonVisible, setSafariMuteButtonVisible] = useState(false)

  const playingVideoRef = useRef(null);
  const setPlayingVideoRef = (ref) => {
    playingVideoRef.current = ref;
  }
  const setListedVideos = (videos) => {
    setShopVideosStates(videos);
  }
  const [isVideoBuffering, setIsVideoBuffering] = useState(true);
  const [autoplayPermissionsDenied, setAutoplayPermissionsDenied] = useState(false);
  const [playList, setPlayList] = useState([]);

  const currentVideo = useMemo(() => playList[playingVideoIndex], [playList, playingVideoIndex])

  useEffect(() => {
    if (initVideoIndex) {
      setPlayingVideoIndex(initVideoIndex)
    }
  }, [initVideoIndex])

  useEffect(() => {
    setIsMuted(isSafari)
    setSafariMuteButtonVisible(isSafari)
  }, [])

  useEffect(() => {
    setPlayList(videos);
    if (videos.length) {
      const newVideoArray = videos.map((video, index) => {
        if (index < shopVideosStates.length) {
          if (shopVideosStates[index].id === video.id) {
            return ({ id: video.id, isPlayed: shopVideosStates[index].isPlayed })
          } else {
            return ({ id: video.id, isPlayed: false })
          }
        } else {
          return ({ id: video.id, isPlayed: false })
        }
      });
      setListedVideos(newVideoArray);
      setIsShopVideoStatesRefreshed(true);

    }

  }, [videos.length])

  const updateVideoPlayingState = (videoIndex) => {
    if (shopVideosStates.length > 0 && playList.length > 0) {
      const storedVideoStates = shopVideosStates;
      const index = storedVideoStates.findIndex(item => item.id === playList[videoIndex].id);
      if (index > -1) {
        storedVideoStates[index].isPlayed = true;
        setListedVideos(storedVideoStates);
      }
    }
  }

  const setActualVideoIndex = (value = 0) => {
    if (value < 0) {
      if (playingVideoIndex > 0) {
        setPlayingVideoIndex(0)
      }
    } else {
      setPlayingVideoIndex(value)
    }
  }

  /** play video after user interaction */
  const playIntroVideo = useCallback(() => {
    setIsUserInteractWithVideoPlayer(true);
    const playPromise = videoElement.current.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => { setAutoplayPermissionsDenied(false); })
    }
  }, [setIsUserInteractWithVideoPlayer, videoElement])

  const onDurationChange = (animationData) => {
    setProgressAnimateDuration(animationData)
  }

  return (
    <VideoStoryContextContext.Provider
      value={{
        currentVideo,
        shopVideosStates,
        setListedVideos,
        isMuted,
        setIsMuted,
        playingVideoIndex,
        setPlayingVideoIndex: setActualVideoIndex,
        setPlayingVideoRef,
        setVideoElement,
        playIntroVideo,
        isUserInteractWithVideoPlayer,
        setIsUserInteractWithVideoPlayer,
        setAutoplayPermissionsDenied,
        playList,
        autoplayPermissionsDenied,
        isVideoBuffering,
        setIsVideoBuffering,
        stateChecked,
        setStateChecked,
        updateVideoPlayingState,
        isShopVideoStatesRefreshed,
        progressAnimateDuration,
        onDurationChange,
        safariMuteButtonVisible,
        setSafariMuteButtonVisible
      }}
    >
      {children}
    </VideoStoryContextContext.Provider>
  )
}

export function useVideoStoryProvider() {
  const context = useContext(VideoStoryContextContext);
  if (!context) {
    throw new Error('useVideoStoryProvider must be used within the scope of VideoStoryContextContext');
  }
  return context;
}
