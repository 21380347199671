import './App.scss';
import { WindowMessagingProvider } from './providers/WindowMessagingProvider';
import React, { useState } from 'react';
import { AppConfigurationProvider } from './providers/AppConfigurationProvider';
import ShoppableVideoStory from './container/shoppableVideoStory';
import { SHOPPABLE_APP_EVENT } from './const';

const App = () => {
  const [configuration, setConfiguration] = useState(null)
  const [cartVariants, setCartVariants] = useState([])

  const onMessageConfigReceived = (config) => {
    if (config) {
      setConfiguration(config)
    } else {
      window.location.reload()
    }
  }

  const onCartVariantsReceived = (cartVariants) => {
    setCartVariants(cartVariants ?? [])
  }

  const onMessageReceived = (type, data) => {
    switch (type) {
      case SHOPPABLE_APP_EVENT.RESET_APP_STATE:
        onMessageConfigReceived(data)
        return;
      case SHOPPABLE_APP_EVENT.UPDATE_CART_CALLBACK:
        onCartVariantsReceived(data)
        return;
    }
  }

  return (
    <WindowMessagingProvider
      onMessageReceived={onMessageReceived}
    >
      <div className={'App'}>
        <AppConfigurationProvider configuration={configuration} cart={cartVariants}>
          <ShoppableVideoStory />
        </AppConfigurationProvider>
      </div>
    </WindowMessagingProvider>
  );
}

export default App;
